import React, { useRef } from "react";
import { HeadProps, PageProps } from "gatsby";
// Components
import { Layout } from "components/Layout";
import Page, { PageSidebar, PageTitle } from "components/Page";
import HTMLContent from "components/HTMLContent";
import HeadTags from "components/Head";
// Types
import { TagContext } from "types/PageContext";

type DataProps = {};

export const TagTemplate: React.FC<PageProps<DataProps, TagContext>> = ({
  pageContext,
}) => {
  const { title, slug } = pageContext;
  const htmlContentRef = useRef<HTMLDivElement>(null);
  return (
    <Layout title={title} slug={slug}>
      <Page id={slug}>
        <PageTitle>{title}</PageTitle>
        <HTMLContent ref={htmlContentRef}>
          {`<p>Sorry, this Tag is Currently Unavailable</p>`}
        </HTMLContent>
      </Page>
      <PageSidebar contentRef={htmlContentRef} />
    </Layout>
  );
};

export const Head = (props: HeadProps<DataProps, TagContext>) => (
  <HeadTags {...props} />
);

export default TagTemplate;
